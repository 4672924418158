import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const PrivacyPage = () => (
  <Layout>
    <SEO title="Privacy Policy" />
    <div className={'container'}>
      <div className={'content'}>
        <div className={'title'}>
          <h1>Privacy Policy</h1>
        </div>

        <p>
          It is Loopclub's policy to respect your privacy regarding any
          information we may collect while operating our website. This Privacy
          Policy applies to{' '}
          <a href="https://loopclub.io">
            https://loopclub.io
          </a>{' '}
          (hereinafter, "us", "we", "Recommendation Kit",
          "https://loopclub.io"). We respect your privacy and
          are committed to protecting personally identifiable information you
          may provide us through the Website. We have adopted this privacy
          policy ("Privacy Policy") to explain what information may be collected
          on our Website, how we use this information, and under what
          circumstances we may disclose the information to third parties.
        </p>
        <p>
          This Privacy Policy applies only to information we collect through the
          Website and does not apply to our collection of information from other
          sources.
        </p>

        <h3>Website Visitors</h3>
        <p>
          Like most website operators, Loopclub collects
          non-personally-identifying information of the sort that web browsers
          and servers typically make available, such as the browser type,
          language preference, referring site, and the date and time of each
          visitor request. Loopclub's purpose in collecting non-personally
          identifying information is to better understand how Loopclub's
          visitors use its website. From time to time, Loopclub may release
          non-personally-identifying information in the aggregate, e.g., by
          publishing a report on trends in the usage of its website.
        </p>
        <p>
          Loopclub also collects potentially personally-identifying information
          like Internet Protocol (IP) addresses for logged in users and for
          users leaving comments on https://loopclub.io . Loopclub only
          discloses logged in user and commenter IP addresses under the same
          circumstances that it uses and discloses personally-identifying
          information as described below.
        </p>

        <h3>Gathering of Personally-Identifying Information</h3>
        <p>
          Certain visitors to Loopclub's websites choose to interact with
          Loopclub in ways that require Loopclub to gather
          personally-identifying information. The amount and type of information
          that Loopclub gathers depends on the nature of the interaction. For
          example, we ask visitors who sign up for Recommendation Kit at
          https://loopclub.io to provide data via Shopify.
        </p>

        <h3>Security</h3>
        <p>
          The security of your Personal Information is important to us, but
          remember that no method of transmission over the Internet, or method
          of electronic storage is 100% secure. While we strive to use
          commercially acceptable means to protect your Personal Information, we
          cannot guarantee its absolute security.
        </p>

        <h3>Advertisements</h3>
        <p>
          Ads appearing on our website may be delivered to users by advertising
          partners, who may set cookies. These cookies allow the ad server to
          recognize your computer each time they send you an online
          advertisement to compile information about you or others who use your
          computer. This information allows ad networks to, among other things,
          deliver targeted advertisements that they believe will be of most
          interest to you. This Privacy Policy covers the use of cookies by
          Loopclub and does not cover the use of cookies by any advertisers.
        </p>

        <h3>Links To External Sites</h3>
        <p>
          Our Service may contain links to external sites that are not operated
          by us. If you click on a third party link, you will be directed to
          that third party's site. We strongly advise you to review the Privacy
          Policy and terms and conditions of every site you visit.
        </p>
        <p>
          We have no control over, and assume no responsibility for the content,
          privacy policies or practices of any third party sites, products or
          services.
        </p>

        <h3>Loopclub uses Google AdWords for remarketing</h3>
        <p>
          <a href="https://loopclub.io">
            https://loopclub.io
          </a>{' '}
          uses the remarketing services to advertise on third party websites
          (including Google) to previous visitors to our site. It could mean
          that we advertise to previous visitors who haven't completed a task on
          our site, for example using the contact form to make an enquiry. This
          could be in the form of an advertisement on the Google search results
          page, or a site in the Google Display Network. Third-party vendors,
          including Google, use cookies to serve ads based on someone's past
          visits. Of course, any data collected will be used in accordance with
          our own privacy policy and Google's privacy policy.
        </p>
        <p>
          You can set preferences for how Google advertises to you using the
          Google Ad Preferences page, and if you want to you can opt out of
          interest-based advertising entirely by cookie settings or permanently
          using a browser plugin.
        </p>

        <h3>Protection of Certain Personally-Identifying Information</h3>
        <p>
          Loopclub discloses potentially personally-identifying and
          personally-identifying information only to those of its employees,
          contractors and affiliated organizations that (i) need to know that
          information in order to process it on Loopclub's behalf or to provide
          services available at Loopclub's website, and (ii) that have agreed
          not to disclose it to others. Some of those employees, contractors and
          affiliated organizations may be located outside of your home country;
          by using Loopclub's website, you consent to the transfer of such
          information to them. Loopclub will not rent or sell potentially
          personally-identifying and personally-identifying information to
          anyone. Other than to its employees, contractors and affiliated
          organizations, as described above, Loopclub discloses potentially
          personally-identifying and personally-identifying information only in
          response to a subpoena, court order or other governmental request, or
          when Loopclub believes in good faith that disclosure is reasonably
          necessary to protect the property or rights of Loopclub, third parties
          or the public at large.
        </p>
        <p>
          If you are a registered user of https://loopclub.io
          and have supplied your email address, Loopclub may occasionally send
          you an email to tell you about new features, solicit your feedback, or
          just keep you up to date with what's going on with Loopclub and our
          products. We primarily use our blog to communicate this type of
          information, so we expect to keep this type of email to a minimum. If
          you send us a request (for example via a support email or via one of
          our feedback mechanisms), we reserve the right to publish it in order
          to help us clarify or respond to your request or to help us support
          other users. Loopclub takes all measures reasonably necessary to
          protect against the unauthorized access, use, alteration or
          destruction of potentially personally-identifying and
          personally-identifying information.
        </p>

        <h3>Aggregated Statistics</h3>
        <p>
          Loopclub may collect statistics about the behavior of visitors to its
          website. Loopclub may display this information publicly or provide it
          to others. However, Loopclub does not disclose your
          personally-identifying information.
        </p>

        <h3>Affiliate Disclosure</h3>
        <p>
          This site uses affiliate links and does earn a commission from certain
          links. This does not affect your purchases or the price you may pay.
        </p>

        <h3>Cookies</h3>
        <p>
          To enrich and perfect your online experience, Loopclub uses "Cookies",
          similar technologies and services provided by others to display
          personalized content, appropriate advertising and store your
          preferences on your computer.
        </p>
        <p>
          A cookie is a string of information that a website stores on a
          visitor's computer, and that the visitor's browser provides to the
          website each time the visitor returns. Loopclub uses cookies to help
          Loopclub identify and track visitors, their usage of
          https://loopclub.io, and their website access
          preferences. Loopclub visitors who do not wish to have cookies placed
          on their computers should set their browsers to refuse cookies before
          using Loopclub's websites, with the drawback that certain features of
          Loopclub's websites may not function properly without the aid of
          cookies.
        </p>
        <p>
          By continuing to navigate our website without changing your cookie
          settings, you hereby acknowledge and agree to Loopclub's use of
          cookies.
        </p>

        <h3>Business Transfers</h3>
        <p>
          If Loopclub, or substantially all of its assets, were acquired, or in
          the unlikely event that Loopclub goes out of business or enters
          bankruptcy, user information would be one of the assets that is
          transferred or acquired by a third party. You acknowledge that such
          transfers may occur, and that any acquirer of Loopclub may continue to
          use your personal information as set forth in this policy.
        </p>

        <h3>Privacy Policy Changes</h3>
        <p>
          Although most changes are likely to be minor, Loopclub may change its
          Privacy Policy from time to time, and in Loopclub's sole discretion.
          Loopclub encourages visitors to frequently check this page for any
          changes to its Privacy Policy. Your continued use of this site after
          any change in this Privacy Policy will constitute your acceptance of
          such change.
        </p>
        <p>Effective from: May 21, 2019</p>

        <h3>Summary</h3>
        <ol>
          <li>
            You provide your personal information (email, phone number, etc.) to
            us while creating an account with us. We store that information
            reliably. We use that information to serve you.
          </li>
          <li>
            When you install our services on your website /mobile app, we
            collect some personally identifiable data to serve you.
          </li>
          <li>
            This policy specifies the data collected by us, the manner of
            collection, and your rights with respect to your personal data.
          </li>
          <li>
            This policy is applicable only to you and not visitor’s of your
            website.
          </li>
          <li>
            You (and/or your users) have an option to opt out from being tracked
            by our services. Please see our opt-out page for more information.
          </li>
        </ol>
        <h3>1. Privacy Statement</h3>
        <p>
          <strong>Loopclub cares about your privacy.</strong>
        </p>
        <ol style={{ listStyleType: 'lower-roman' }}>
          <li>
            Loopclub is committed to conducting business in a compliant manner
            and protecting the privacy of individuals who visit Recommendation
            Kit website and/or interact with Recommendation Kit online
            (“User/’Users”), Users who register to use the Services as defined
            below (“Customers”), and Users who provide information to
            Recommendation Kit to register for events (“Attendees”). This
            Privacy Statement describes Loopclub’s privacy practices in relation
            to the use of Recommendation Kit websites and the related products,
            applications, and services (including support) offered by
            Recommendation Kit (the “Services”).
          </li>
          <li>
            When Loopclub receives personal information, Loopclub maintains high
            ethical standards and takes steps to handle personal information in
            a responsible manner that is consistent with our core values and
            business objectives.
          </li>
          <li>
            By using our website and agreeing to this policy, you consent to our
            use of cookies in accordance with the terms of this policy.
          </li>
          <li>
            Loopclub may modify or update this privacy statement at any time
            without prior notice. You can check the “Last Updated” date above to
            see when the statement was last changed. Your continued use of the
            website and/or the Services constitutes your consent to the contents
            of this Privacy Statement.
          </li>
        </ol>
        <h3>2. Overview</h3>
        <p>
          Loopclub collects many kinds of information to operate effectively and
          provide you the best products, services, and experiences possible.
          Loopclub collects information when you register, sign in, and use our
          sites and services. We collect this information in a variety of ways,
          including web forms, technologies like cookies, web logging, and/or
          others.
        </p>
        <p>
          We respect each individual’s right to personal privacy. We will
          collect and use the information we receive directly from you through
          our website only in the ways disclosed in this Privacy Policy. This
          Privacy Policy applies solely to information we collect directly from
          you through the “Services”. The data/information collected by the
          customer will be subject to their own Privacy policy in force.
          Loopclub’s Privacy Policy does not apply to any end user data that it
          may collect, obtain, or access in connection with operating the
          specific service on behalf of its customers. By visiting the Website,
          you acknowledge that you accept the practices and policies outlined in
          this Privacy Policy.
        </p>
        <p>
          Loopclub receives and processes information (in paper and electronic
          form) in accordance with its clients’ instructions for providing
          services to its customers. At Loopclub, we recognize the importance of
          privacy to our customers and we strive to safeguard any personal
          information we receive and may need to use in support of our
          customers.
        </p>
        <h3>3. What Personal Information does Loopclub collect or maintain?</h3>
        <p>
          Loopclub collects two types of information:{' '}
          <strong>personal information</strong> and{' '}
          <strong>non-personally identifiable information</strong> to operate
          effectively and provide you the best products, services, and
          experiences possible. Loopclub collects information when you register,
          sign in, and use our sites and services. We collect this information
          in a variety of ways, including web forms, technologies like cookies,
          web logging, and or other methods. We may use personal and
          non-personally identifiable information to create
          <strong> aggregate information</strong> (collectively referred to as
          “automated information”). We collect the following categories and
          elements of information:
        </p>
        <ol style={{ listStyleType: 'lower-roman' }}>
          <li>
            In general, as a User you will not need to identify yourself or
            reveal any personal information. However, Loopclub does collect
            domain information from your visit to customize and improve your
            experience on our websites. The type of information Loopclub may
            collect includes:
            <ol style={{ listStyleType: 'lower-alpha' }}>
              <li>the date and time of your access</li>
              <li>the pages you have accessed</li>
              <li>
                the Internet Protocol (IP) address that you use to access the
                Internet
              </li>
            </ol>
          </li>
          <li>
            There are times when Loopclub may collect personal information from
            you as a User, Customer, or Attendee. This may include your name,
            email address, phone number, and other contact information (browser
            type and version, country, etc). In some cases, Loopclub may request
            additional information in which case you may receive a separate
            privacy statement.&nbsp; Some of the ways in which Loopclub may
            collect information from you are:
            <ol style={{ listStyleType: 'lower-alpha' }}>
              <li>
                Registrations for webcasts, seminars, conferences, etc.,
                sponsored by Loopclub or a partner
              </li>
              <li>
                Subscription to newsletters or other Loopclub content-related
                materials
              </li>
              <li>
                Service purchases or trials (either on your own or on behalf of
                your company)
              </li>
            </ol>
          </li>
        </ol>
        <p>
          Users of this Website are not requested to provide{' '}
          <strong>sensitive personal information</strong> (personal information
          revealing racial or ethnic origin, political opinions, religious or
          philosophical beliefs, trade union membership, genetic data, or
          biometric data for uniquely identifying a natural person, information
          concerning health or information concerning a natural person’s sex
          life or sexual orientation). Please avoid providing such information
          through this Website.
        </p>
        <p>
          We will collect personal information from Users to this Website,
          including automated information collected in server log files;
          personal information deliberately provided by the Website user through
          web forms, subscription pages, and other similar means; and personal
          information collected through cookies, web beacons, and similar
          technology.
        </p>

        <h3>4. Information automatically collected</h3>
        <p>
          When you use our Website, we automatically collect your IP address,
          traffic source, search keywords, page views, visits, location
          (aggregated), browser and operating systems (aggregated), device
          (aggregated), as well as other connection information like time and
          transferred information (collectively referred to as “automated
          information”).
        </p>
        <p>
          We store and use this automated information in server log files for
          system integrity and security and in order to be able to investigate
          and prosecute infringements or abuse of our Website and to cooperate
          with authorities involved (security and prosecution purposes). We also
          use this information to enhance our services and to provide you with a
          positive user experience (product and analytics purposes).
        </p>
        <p>
          For these purposes, we make accessible and share automated information
          with our security team (for security and prosecution purposes),
          supervisory authorities, and qualified personnel (product, marketing,
          analytics) having access to the tools that are used for collecting and
          analyzing automated information (Google Analytics and others, see
          below in sections “Google Analytics” and “Marketing automation and
          customer relationship management (CRM).”
        </p>
        <p>
          We store the information required for security and prosecution
          purposes on a legitimate interest basis and the information required
          for product, marketing, and analytics purposes (legitimate interest or
          where required, by obtaining your prior consent), for the period
          necessary to pursue the purposes outlined in this section, or as
          permitted by statutory applicable laws.
        </p>
        <p>
          <strong>
            Information you submit through web forms and website pages
          </strong>
        </p>
        <p>
          On our Website, you can pose inquiries or get in touch with us through
          web forms, ordering pages, subscribing to our blogs and event alerts,
          or by registering for services.
        </p>
        <p>
          We may collect, store, and use your name, company name, email address,
          IP address, the content of your request or query and other personal
          information you provide with your submission for contacting you,
          responding to your request, answering your questions, or providing
          information as requested.
        </p>
        <p>
          We will also collect information about the content that Users access
          through this Website, such as newsletters or articles or the
          informational topics contained therein, or by sending information to a
          Recommendation Kit email address provided on the Recommendation Kit
          website. We will use this information to manage Usersubscriptions and
          to inform Users about Recommendation Kit services and information that
          may be of interest, unless Users inform us that they no longer wish to
          receive any promotional material from Recommendation Kit.
        </p>
        <p>
          We collect and use this information on a legitimate interest purpose
          (or, where required, by obtaining your prior consent). We store this
          data for the period necessary to pursue the purposes outlined in this
          section, or as permitted by applicable statutory laws.
        </p>
        <p>
          <strong>Double Opt-in procedure for EU users</strong>
        </p>
        <p>
          The sign-up to receive our newsletters, invitations, and other
          information takes place in a so-called double opt-in procedure, i.e.,
          after signing up, you will receive an email in which you will be asked
          to confirm your registration. The registrations are logged to be able
          to prove the registration process. This includes your name, email
          address, the time of registration and confirmation, and your IP
          address.
        </p>
        <p>
          <strong>Your inquiries sent by email</strong>
        </p>
        <p>
          We appreciate your queries about our product and services and welcome
          your email messages to mailboxes listed on our Website. We will share
          your messages with those within our organization that are most capable
          of addressing the issues contained in your message. We will keep a
          copy of your message until we have had an opportunity to address your
          concerns. We may archive your message for a certain period or discard
          it, as appropriate or as required by applicable data retention laws.
        </p>

        <h3>5. How does Loopclub use your information?</h3>
        <p>
          <strong>Shopify Data</strong>
        </p>
        <p>
          We collect data from Shopify as soon as you install our app. We use
          that data solely for the purpose of providing better user experience
          to your website's visitors. This list of data collected is displayed
          when you install the app on Shopify. Once you uninstall the app from
          Shopify this dats is deleted within 24 hours of us successfully
          receiving the request from Shopify via webhook.
        </p>
        <h3>5. How does Loopclub use your information?</h3>
        <ol style={{ listStyleType: 'lower-roman' }}>
          <li>
            Loopclub uses the information collected to operate its websites to
            provide you with the Services requested and to contact you about
            your interest in the Services; to verify your information; and to
            plan and host corporate webinars, online forums, and social
            networks. Loopclub may also aggregate your information with data
            that is separately provided by you on social media and third-party
            websites if visible to third parties. Loopclub may also use data
            from Users, Customers, and Attendees for marketing purposes. For
            example, Loopclub may use the information you provide to contact you
            to further discuss your interest in the Services and to send you
            information regarding Loopclub, its affiliates, and its partners,
            such as information about promotions or events.
          </li>
          <li>
            When Loopclub collects information such as your name or other
            details about you, it is Loopclub’s practice to provide you with
            information on how the data will be used at the time you are asked
            for it if that is not immediately apparent. When Loopclub asks you
            to provide your information through a registration page or
            otherwise, you will have the option to not consent to the use of
            your personal information to contact you, in which case you may
            still be able to access other portions of this website, although you
            may not be able to access certain sections or services.
          </li>
          <li>
            Refer to heading 8. “What rights do you have in connection with the
            personal data Loopclub collects?” for information on your rights,
            including the right to “opt out” of certain uses of your
            information.
          </li>
        </ol>

        <h3>
          6. How does Loopclub address data transfers from the European Economic
          Area (EEA)?
        </h3>
        <p>
          All exports of data from within the European Economic Area (EEA) to
          non-EEA countries (referred to in the GDPR as “third countries”) are
          unlawful, unless there is an appropriate “level of protection for the
          fundamental rights of the data subjects”. The transfer of personal
          data outside of the EEA is prohibited unless one or more of the
          specified safeguards, or exceptions, apply:
        </p>
        <table>
          <tbody>
            <tr>
              <td>
                <strong>Safeguards </strong>
              </td>
              <td>
                <strong>Data Processor</strong>
              </td>
              <td>
                <strong>Data Controller </strong>
              </td>
              <td>
                <strong>Links</strong>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Binding Corporate Rules, or BCRs (controller)</strong>
              </td>
              <td>&nbsp;</td>
              <td align="center">x</td>
              <td>
                Contact us at{' '}
                <a href="mailto:support@loopclub.io">support@loopclub.io</a> for
                more details about our BCRs.
              </td>
            </tr>
            <tr>
              <td>
                <strong>Data Protection Addendum (Customer/Loopclub)</strong>
              </td>
              <td align="center">x</td>
              <td>&nbsp;</td>
              <td>
                Contact us at{' '}
                <a href="mailto:support@loopclub.io">support@loopclub.io</a> for
                more details about our BCRs.
              </td>
            </tr>
            <tr>
              <td>
                <strong>
                  Standard Contractual Clauses (Customer/Loopclub)
                </strong>
              </td>
              <td align="center">x</td>
              <td>&nbsp;</td>
              <td>
                Contact us at{' '}
                <a href="mailto:support@loopclub.io">support@loopclub.io</a> for
                more details about our BCRs.
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          <em>
            Loopclub is generally a <strong>data controller</strong> when you
            provide your data as a User or Attendee, or to register for a
            Service.
          </em>
        </p>
        <p>
          <em>
            Loopclub is generally a <strong>data processor</strong> when your
            company provides Recommendation Kit with access to personal data
            originating from your company, e.g., a file for product support or
            other Services or data in a SaaS environment.
          </em>
        </p>
        <p>
          <strong>Binding Corporate Rules</strong>
        </p>
        <p>
          Our BCRs are certain objectives, including those outlined below, with
          respect to the handling of personal data:
        </p>
        <table>
          <tbody>
            <tr>
              <td>
                <strong>Focus Areas</strong>
              </td>
              <td>
                <strong>Objectives</strong>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Limitation of purpose and Data Flow</strong>
              </td>
              <td>
                Personal data is collected and processed for legitimate purposes
                and in accordance with Loopclub Policies and Procedures.
              </td>
            </tr>
            <tr>
              <td>
                <strong>Data quality and proportionality</strong>
              </td>
              <td>
                Personal data is kept accurate, is not excessive, and is
                disseminated in a secure manner.
              </td>
            </tr>
            <tr>
              <td>
                <strong>
                  Right of access, rectification, erasure, and blocking of data
                </strong>
              </td>
              <td>
                Data subjects may exercise rights to their data such as access,
                rectification, and erasure.
              </td>
            </tr>
            <tr>
              <td>
                <strong>Security and confidentiality </strong>
              </td>
              <td>
                Appropriate security controls are in place to protect data
                against unauthorized access, accidental alteration, and
                destruction.
              </td>
            </tr>
            <tr>
              <td>
                <strong>Restriction on transfer and onward transfers</strong>
              </td>
              <td>
                Data transferred to a third party is under appropriate agreement
                with adequate levels of protection.
              </td>
            </tr>
            <tr>
              <td>
                <strong>Cooperation with data protection authorities </strong>
              </td>
              <td>
                Loopclub is committed to cooperating with data protection
                authorities and with individuals handling complaints.
              </td>
            </tr>
          </tbody>
        </table>

        <h3>
          7. What are Loopclub’s security and confidentiality levels for your
          data?
        </h3>
        <p>
          Loopclub’s intent is to strictly protect the security of your personal
          information; honor your choice for its intended use; and carefully
          protect your data from loss, misuse, unauthorized access or
          disclosure, alteration, or destruction.
          <br /> We restrict access to personal information collected about you
          at our Website to our employees, our affiliate’s employees, or others
          who need to know that information to provide services to you or while
          conducting our normal business operations.
        </p>
        <p>
          At Loopclub, we maintain appropriate physical, electronic, and
          procedural safeguards to protect personal information. We protect our
          databases with various physical, technical, and procedural measures,
          and we restrict access to your information by unauthorized persons. We
          also advise all Loopclub employees about their responsibility to
          protect customer data, and we provide them with appropriate guidelines
          for adhering to our company’s business ethics standards and
          confidentiality policies.
        </p>
        <p>
          We have taken appropriate steps to safeguard and secure the
          information we collect, including the use of encryption,
          pseudonymization, anonymization, apply secure sockets layer (SSL) for
          information in transit, when collecting or transferring personal
          information. Contact us at{' '}
          <a href="mailto:support@loopclub.io">support@loopclub.io</a> for more
          details on how Loopclub secures information.
        </p>

        <h3>
          8. What Rights do you have in connection with the personal information
          that Loopclub collects?
        </h3>
        <p>
          We have taken all necessary and adequate steps to protect your
          personal information and ensure your rights.
        </p>
        <p>
          You have certain rights described below. Please note that limitations
          may apply to your ability to exercise these rights, for example, when
          your right to obtain the information is found to be overwritten by
          essential considerations of overriding interests.
        </p>
        <ol style={{ listStyleType: 'lower-roman' }}>
          <li>
            <strong>Right of access</strong>
            <br /> You have the right to request access to the personal
            information we process about you.
            <p></p>
            <p>
              We will provide you with a copy of the personal information
              undergoing processing as a starting point, free of charge or by
              electronic means, if the request has been submitted in a commonly
              used electronic form or through{' '}
              <a href="mailto:support@loopclub.io">support@loopclub.io</a>.
            </p>
          </li>
          <li>
            <strong>Right to rectification</strong>
            <br /> You have the right to rectification of inaccurate personal
            data concerning you, including completion of incomplete personal
            data. Contact Loopclub at{' '}
            <a href="mailto:support@loopclub.io">support@loopclub.io</a> for any
            questions or to update your information.
          </li>
          <li>
            <strong>Right to erasure (right to be forgotten)</strong>
            <br /> Under certain circumstances, you have the right to the
            erasure of the personal information concerning you. Contact Loopclub
            at <a href="mailto:support@loopclub.io">support@loopclub.io</a> for
            any questions or to update your information.
          </li>
          <li>
            <strong>Right to restriction</strong>
            <br /> Under certain circumstances, you have the right to restrict
            our processing of personal information concerning you. Contact
            Loopclub at{' '}
            <a href="mailto:support@loopclub.io">support@loopclub.io</a> for any
            questions or to update your information.
          </li>
          <li>
            <strong>Right to data portability</strong>
            <br /> Where processing is based on consent or a contract and the
            processing is carried out by automated means, you have the right to
            receive the personal information concerning you in a structured,
            commonly used, and machine-readable format. You have the right to
            transmit this personal information to a third party without
            hindrance from us, if technically possible. Contact Loopclub at{' '}
            <a href="mailto:support@loopclub.io">support@loopclub.io</a> for any
            questions or to update your information.
          </li>
          <li>
            <strong>Rights to object</strong>
            <br /> Under certain circumstances, you have the right to object at
            any time to our processing of personal information concerning you.
            For example, if you have requested to receive information from us,
            e.g., newsletters, but do not wish to receive further information,
            you can easily opt out of receiving further information from us.
            Contact Loopclub at{' '}
            <a href="mailto:support@loopclub.io">support@loopclub.io</a> for any
            questions or to update your information.
          </li>
          <li>
            <strong>
              Automated individual decision-making, including profiling
            </strong>
            <br /> As a rule, you have the right not to be subject to a decision
            based solely on automated processing, including profiling, which
            produces legal effects or affects you significantly. This does not
            apply if among other things automated decision-making and profiling
            is necessary for entering into, or performance of, a contract
            between you and us. Contact Loopclub at{' '}
            <a href="mailto:support@loopclub.io">support@loopclub.io</a> for any
            questions or to update your information.
          </li>
          <li>
            <strong> Right to withdraw consent</strong>
            <br /> If processing of your personal information is based on your
            consent, you may withdraw your consent at any time by unsubscribing
            from an email or sending us an email. Please note that this does not
            affect the processing of your personal data prior to the withdrawal
            of your consent.
          </li>
          <li>
            <strong>Exercising your rights</strong>
            <br /> If you want to exercise any of your rights as described above
            or have any questions, please contact our Data Protection Officer by
            sending an email to{' '}
            <a href="mailto:support@Loopclub.com">support@Loopclub.com</a>.
          </li>
          <li>
            <strong>Filing of a complaint</strong>
            <br /> If you wish to file a complaint regarding our processing of
            your personal data, you can choose to file a complaint to a
            supervisory Data Protection Authority.
          </li>
        </ol>
        <p>
          <strong>
            What other rights do you have in connection with the personal data
            Loopclub collects?
          </strong>
        </p>
        <ol style={{ listStyleType: 'upper-roman' }}>
          <li>
            <strong>What choices do I have?</strong>
            <br /> You can always opt not to disclose information to us, but
            keep in mind some information may be needed to register with us or
            to take advantage of some of our features.
          </li>
          <li>
            <strong>&nbsp;Cookies</strong>
            <br /> You can set or amend your web browser controls to accept or
            reject cookies. If you reject cookies, you may still use our
            Websites and Services; however, your access to some functionalities
            and areas of our Websites or Services may be severely restricted.
          </li>
          <li>
            <strong>Marketing Communications</strong>
            <br /> You can opt out of receiving certain promotional or marketing
            communications from us at any time by clicking the Unsubscribe link
            in the email communications we send or by contacting us at{' '}
            <a href="mailto:support@loopclub.io">support@loopclub.io</a> with
            the word “UNSUBSCRIBE” in the subject field of the email. If you
            have any account for our Services, we will still send you
            non-promotional communications, like service-related emails.
            <p></p>
            <p>
              Loopclub offers Users, Customers, and Attendees the means to
              choose how Loopclub uses the personal data they provide. You may
              manage your receipt of marketing and non-transactional
              communications by clicking on the “unsubscribe” link located at
              the bottom of Loopclub marketing emails. Additionally, you may
              send a request to
              <a href="mailto:support@loopclub.io"> support@loopclub.io</a>.
            </p>
          </li>
          <li>
            &nbsp;<strong>What if I have questions about this policy?</strong>
            <br /> If you have any questions or concerns regarding our privacy
            policy, please send us a detailed message to{' '}
            <a href="mailto:support@loopclub.io">support@loopclub.io</a>, and we
            will try to resolve your concerns.
          </li>
        </ol>

        <h3>9. Information Retention</h3>
        <p>
          How long we keep the personal information we collect depends on the
          type of information, the purpose for which it is used and similar
          factors. In general, we will retain your personal information for the
          length of time reasonably needed to fulfill the purpose outlined in
          this privacy policy (including for a long as needed to provide you or
          our customers with products and services), unless a longer retention
          period is required or permitted by law.
        </p>
        <p>
          We will also retain and use your information for long as necessary to
          resolve disputes and/or enforce our rights and agreements.
          Non-personally identifiable and aggregated information may be stored
          for longer duration.
        </p>

        <h3>10. What if you post on Loopclub social media pages?</h3>
        <ol style={{ listStyleType: 'lower-roman' }}>
          <li>
            <strong>Social Media:</strong> Our website includes Social Media
            features, such as Facebook-like buttons and widgets, such as the
            “Share this” button or interactive mini-programs that run on our
            site. These features may collect your IP address, the page you are
            visiting on our site, and may set a cookie to enable the feature to
            function properly. Social Media features and widgets are either
            hosted by a third-party or hosted directly on our Site. Your
            interactions with these features are governed by the privacy policy
            of the company providing it.
          </li>
          <li>
            <strong>Testimonial:</strong> With your consent, we may post your
            testimonial along with your name. If you wish to update or delete
            your testimonial, you can contact us at{' '}
            <a href="mailto:support@loopclub.io">support@loopclub.io</a>.
          </li>
        </ol>

        <h3>11. How does Loopclub share your information?</h3>
        <ol style={{ listStyleType: 'lower-roman' }}>
          <li>
            Loopclub may share your personal data with third-parties like
            Suppliers and Business Partners, to use their services and may also
            jointly host events or webinars or collaborate on any other front;
            and we require that such third-parties comply with our Privacy
            Policy and any other appropriate confidentiality and security
            measures.
          </li>
          <li>
            <strong>Suppliers:</strong>
            <br /> Loopclub may use third-party vendors to offer its services
            and may also jointly host events and webinars with partners.
          </li>
          <li>
            <strong>
              Business Partners:
              <br />{' '}
            </strong>{' '}
            Loopclub may use partners to offer its services and may also jointly
            host events and webinars with partners.
          </li>
          <li>
            <strong>Third-Party Advertising:&nbsp;</strong>
            <br /> Loopclub may use third party advertising services to offer
            its services and may also reach out to you through these platforms.
          </li>
        </ol>

        <h3>12. What are Loopclub’s practices regarding cookies?</h3>
        <ol style={{ listStyleType: 'lower-roman' }}>
          <li>
            Cookies are small files stored on your computer and are useful for
            enabling the browser to remember information specific to a user. We
            place cookies in a customer’s browser to identify the customer. If
            you do not wish to provide any information through cookies to
            Loopclub, you may choose not to use the services. We may also
            collect and store information locally on your device using
            mechanisms such as browser web storage.
          </li>
          <li>
            Cookies Consent Manager: We provide all information regarding
            third-party cookies through our cookie consent manager on the
            Recommendation Kit website.
          </li>
          <li>
            How to reset cookie preferences: Users can reset their cookie
            preferences by clearing their browser cookies. Reference link:{' '}
            <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&amp;hl=en">
              https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&amp;hl=en
            </a>
          </li>
        </ol>
        <p>
          We entered into data processing agreements as required with
          third-party recipients to protect your personal information and
          interests. In these data processing agreements, the service providers
          undertake measures to protect the data of our users, to process it on
          our behalf in accordance with the applicable data protection
          regulations and, in particular, not to pass the data on to third
          parties. Loopclub thus ensures that your data are processed at a level
          of data protection that corresponds to the level of data protection in
          the EU/European Economic Area.
        </p>
        <h3>13. Children</h3>
        <p>
          We are committed to preserving online privacy for all the Users,
          Customers, and Attendees to our Website, including children. Our
          Website is a general audience Website, and we do not knowingly collect
          personal information about children or provide services to children.
          Consistent with applicable laws, we will not knowingly collect any
          information from children under the age of 16 years. The Website is
          not designed to attract anyone under the age of 16, please do not
          attempt to register for the services or send any information about
          yourself to us.
        </p>
        <p>
          If you believe that a child under 16 years may have provided us with
          Personal Information, please contact us at{' '}
          <a href="mailto:support@Loopclub.com">support@Loopclub.com</a>.
        </p>
        <h3>14. Data Protection Officer</h3>
        <p>
          The Loopclub has appointed a data protection officer for you to
          contact if you have any questions or concerns about Loopclub’s
          personal data policies or practices. Loopclub’s Data Protection
          Officers and contact information:{' '}
          <a href="mailto:support@Loopclub.com">support@Loopclub.com</a>.
        </p>
      </div>
    </div>
  </Layout>
)

export default PrivacyPage
